<template>
  <div
    :class="[
      sizeClassesWrap?.[size as keyof typeof sizeClassesWrap] ||
        sizeClassesWrap.base,
    ]"
  >
    <button
      v-for="(label, index) in labels"
      :key="index"
      :class="[
        sizeClasses?.[size as keyof typeof sizeClasses] || sizeClasses.base,
        !interactive ? 'pointer-events-none' : '',
        !interactive || label[valueKey] === modelValue
          ? 'bg-black/[.87] text-white'
          : 'bg-black/6 text-black/54 hover:bg-black/12',
      ]"
      @click="interactive && labelClicked(label[valueKey])"
    >
      {{ label[titleKey] }}
    </button>
  </div>
</template>

<script lang="ts">
// See why this is needed: https://stackoverflow.com/questions/69951687/vue-3-defineprops-are-referencing-locally-declared-variables
const sizeClasses = {
  sm: 'px-3 py-0.5 text-xs rounded-2xl',
  base: 'px-4 py-1 text-sm rounded-2xl',
  lg: 'px-5 py-2 md:py-1 text-sm md:rounded-2xl rounded-xl',
};
const sizeClassesWrap = {
  sm: 'flex flex-wrap gap-2',
  base: 'flex flex-wrap gap-2',
  lg: 'flex flex-wrap gap-3 md:gap-2',
};
</script>

<script setup lang="ts">
defineProps({
  labels: {
    type: Array as () => any[],
    default: () => [],
  },
  titleKey: {
    type: String,
    default: 'title',
  },
  valueKey: {
    type: String,
    default: 'value',
  },
  modelValue: {
    type: [String, Boolean, Number],
    default: '',
  },
  interactive: {
    type: Boolean,
    default: true,
  },
  size: {
    type: String,
    default: 'base',
    validator: (value: string) => Object.keys(sizeClasses).includes(value),
  },
});

const emit = defineEmits(['update:labels']);

const labelClicked = (label: any) => {
  emit('update:labels', label);
};
</script>
